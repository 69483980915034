import React , { useState, useEffect, useCallback } from 'react';
import { Link, graphql } from 'gatsby';

import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Pagination, Thumbs } from 'swiper';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';


import { Script } from "gatsby";
import { 
  LayoutLp,
  SEO,
  AssetImage,
  CLpModal,
} from '../../../components/_index';
import { useMobile } from '../../../utils/use-series';
import '../../../assets/_sass/page/sp/christmas2024.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const isSp = useMobile();
  // headerイベントコメントアウト
  // const [scrollFlag, setScrollFlag] = useState<Boolean>(false);
  // useEffect(() => {
  //   window.addEventListener('scroll', scrollEvent);
  //   return () => window.removeEventListener('scroll', scrollEvent);
  // }, []);
  // const scrollEvent = useCallback(() => {
  //   if (
  //     window.scrollY > document.querySelectorAll('.l_headerLpChristmas')[0].clientHeight
  //   ) {
  //     document.querySelectorAll('.lp_xmasFooter')[0].classList.remove('disnon');
  //   }
  // }, [scrollFlag]);

  // Swiper
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [thumbsSwiper1, setThumbsSwiper1] = useState(null);
  const [thumbsSwiper2, setThumbsSwiper2] = useState(null);
  const [thumbsSwiper3, setThumbsSwiper3] = useState(null);

  // modalSwiper
  const [thumbsSwiper4, setThumbsSwiper4] = useState(null);

  // modal
  // event
  const [modalFlagEvent, setModalFlagEvent] = useState(false);
  // cake&dish&course
  const [modalFlag, setModalFlag] = useState(false);
  const [modalFlag02, setModalFlag02] = useState(false);
  const [modalFlag03, setModalFlag03] = useState(false);
  const [modalFlag04, setModalFlag04] = useState(false);
  const [modalFlag05, setModalFlag05] = useState(false);
  const [modalFlag06, setModalFlag06] = useState(false);
  const [modalFlag07, setModalFlag07] = useState(false);
  const [modalFlag08, setModalFlag08] = useState(false);
  const [modalFlag09, setModalFlag09] = useState(false);
  const [modalFlag10, setModalFlag10] = useState(false);
  const [modalFlag11, setModalFlag11] = useState(false);
  const [modalFlag12, setModalFlag12] = useState(false);
  const [modalFlag13, setModalFlag13] = useState(false);
  const [modalFlag14, setModalFlag14] = useState(false);
  const [modalFlag15, setModalFlag15] = useState(false);
  const [modalFlag16, setModalFlag16] = useState(false);
  const [modalFlag17, setModalFlag17] = useState(false);
  const [modalFlag18, setModalFlag18] = useState(false);
  const [modalFlag19, setModalFlag19] = useState(false);
  const [modalFlag20, setModalFlag20] = useState(false);
  const [modalFlag21, setModalFlag21] = useState(false);
  const [modalFlag22, setModalFlag22] = useState(false);

  const openModalEvent = (e: any) => {
    e.stopPropagation();
    setModalFlagEvent(!modalFlagEvent);
  };

  const openModal = (e: any) => {
    e.stopPropagation();
    setModalFlag(!modalFlag);
    const body = document.body;
    if (modalFlag) {
      body.style.overflow = 'auto';
    } else {
      body.style.overflow = 'hidden';
    }
  };
  const openModal02 = (e: any) => {
    e.stopPropagation();
    setModalFlag02(!modalFlag02);
    const body = document.body;
    if (modalFlag02) {
      body.style.overflow = 'auto';
    } else {
      body.style.overflow = 'hidden';
    }
  };
  const openModal03 = (e: any) => {
    e.stopPropagation();
    setModalFlag03(!modalFlag03);
    const body = document.body;
    if (modalFlag03) {
      body.style.overflow = 'auto';
    } else {
      body.style.overflow = 'hidden';
    }
  };
  const openModal04 = (e: any) => {
    e.stopPropagation();
    setModalFlag04(!modalFlag04);
    const body = document.body;
    if (modalFlag04) {
      body.style.overflow = 'auto';
    } else {
      body.style.overflow = 'hidden';
    }
  };
  const openModal05 = (e: any) => {
    e.stopPropagation();
    setModalFlag05(!modalFlag05);
    const body = document.body;
    if (modalFlag05) {
      body.style.overflow = 'auto';
    } else {
      body.style.overflow = 'hidden';
    }
  };
  const openModal06 = (e: any) => {
    e.stopPropagation();
    setModalFlag06(!modalFlag06);
    const body = document.body;
    if (modalFlag06) {
      body.style.overflow = 'auto';
    } else {
      body.style.overflow = 'hidden';
    }
  };
  const openModal07 = (e: any) => {
    e.stopPropagation();
    setModalFlag07(!modalFlag07);
    const body = document.body;
    if (modalFlag07) {
      body.style.overflow = 'auto';
    } else {
      body.style.overflow = 'hidden';
    }
  };
  const openModal08 = (e: any) => {
    e.stopPropagation();
    setModalFlag08(!modalFlag08);
    const body = document.body;
    if (modalFlag08) {
      body.style.overflow = 'auto';
    } else {
      body.style.overflow = 'hidden';
    }
  };
  const openModal09 = (e: any) => {
    e.stopPropagation();
    setModalFlag09(!modalFlag09);
    const body = document.body;
    if (modalFlag09) {
      body.style.overflow = 'auto';
    } else {
      body.style.overflow = 'hidden';
    }
  };
  const openModal10 = (e: any) => {
    e.stopPropagation();
    setModalFlag10(!modalFlag10);
    const body = document.body;
    if (modalFlag10) {
      body.style.overflow = 'auto';
    } else {
      body.style.overflow = 'hidden';
    }
  };
  const openModal11 = (e: any) => {
    e.stopPropagation();
    setModalFlag11(!modalFlag11);
    const body = document.body;
    if (modalFlag11) {
      body.style.overflow = 'auto';
    } else {
      body.style.overflow = 'hidden';
    }
  };
  const openModal12 = (e: any) => {
    e.stopPropagation();
    setModalFlag12(!modalFlag12);
    const body = document.body;
    if (modalFlag12) {
      body.style.overflow = 'auto';
    } else {
      body.style.overflow = 'hidden';
    }
  };
  const openModal13 = (e: any) => {
    e.stopPropagation();
    setModalFlag13(!modalFlag13);
    const body = document.body;
    if (modalFlag13) {
      body.style.overflow = 'auto';
    } else {
      body.style.overflow = 'hidden';
    }
  };
  const openModal14 = (e: any) => {
    e.stopPropagation();
    setModalFlag14(!modalFlag14);
    const body = document.body;
    if (modalFlag14) {
      body.style.overflow = 'auto';
    } else {
      body.style.overflow = 'hidden';
    }
  };
  const openModal15 = (e: any) => {
    e.stopPropagation();
    setModalFlag15(!modalFlag15);
    const body = document.body;
    if (modalFlag15) {
      body.style.overflow = 'auto';
    } else {
      body.style.overflow = 'hidden';
    }
  };
  const openModal16 = (e: any) => {
    e.stopPropagation();
    setModalFlag16(!modalFlag16);
    const body = document.body;
    if (modalFlag16) {
      body.style.overflow = 'auto';
    } else {
      body.style.overflow = 'hidden';
    }
  };
  const openModal17 = (e: any) => {
    e.stopPropagation();
    setModalFlag17(!modalFlag17);
    const body = document.body;
    if (modalFlag17) {
      body.style.overflow = 'auto';
    } else {
      body.style.overflow = 'hidden';
    }
  };
  const openModal18 = (e: any) => {
    e.stopPropagation();
    setModalFlag18(!modalFlag18);
    const body = document.body;
    if (modalFlag18) {
      body.style.overflow = 'auto';
    } else {
      body.style.overflow = 'hidden';
    }
  };
  const openModal19 = (e: any) => {
    e.stopPropagation();
    setModalFlag19(!modalFlag19);
    const body = document.body;
    if (modalFlag19) {
      body.style.overflow = 'auto';
    } else {
      body.style.overflow = 'hidden';
    }
  };
  const openModal20 = (e: any) => {
    e.stopPropagation();
    setModalFlag20(!modalFlag20);
    const body = document.body;
    if (modalFlag20) {
      body.style.overflow = 'auto';
    } else {
      body.style.overflow = 'hidden';
    }
  };
  const openModal21 = (e: any) => {
    e.stopPropagation();
    setModalFlag21(!modalFlag21);
    const body = document.body;
    if (modalFlag21) {
      body.style.overflow = 'auto';
    } else {
      body.style.overflow = 'hidden';
    }
  };
  const openModal22 = (e: any) => {
    e.stopPropagation();
    setModalFlag22(!modalFlag22);
    const body = document.body;
    if (modalFlag22) {
      body.style.overflow = 'auto';
    } else {
      body.style.overflow = 'hidden';
    }
  };

  return (
    <LayoutLp isChristmas={true}>
      <Script dangerouslySetInnerHTML={{
        __html: `
(function() {
    const tabList = document.querySelectorAll('.lp_tabItem');
    const tabListSp = document.querySelectorAll('.lp_tabItemSp');
    const tabContent = document.querySelectorAll('.lp_tabContentsItem');
    const tabContentSp = document.querySelectorAll('.lp_tabContentsItemSp');

    function tabSwitch() {
      document.querySelectorAll('.active')[0].classList.remove('active');
      this.classList.add('active');
      document.querySelectorAll('.show')[0].classList.remove('show');
      const aryTabs = Array.prototype.slice.call(tabList);
      const index = aryTabs.indexOf(this);
      tabContent[index].classList.add('show');
    };
    for (let i = 0; i < tabList.length; i++) {
      tabList[i].addEventListener('click', tabSwitch);
    }

    function tabSwitchSp() {
      document.querySelectorAll('.activeSp')[0].classList.remove('activeSp');
      this.classList.add('activeSp');
      document.querySelectorAll('.showSp')[0].classList.remove('showSp');
      const aryTabs = Array.prototype.slice.call(tabListSp);
      const index = aryTabs.indexOf(this);
      tabContentSp[index].classList.add('showSp');
    };
    for (let i = 0; i < tabListSp.length; i++) {
      tabListSp[i].addEventListener('click', tabSwitchSp);
    }
})(document)

        `}} />
      <SEO
        title={frontmatter?.title}
        description={frontmatter?.description}
        ogimage={`${process.env.BASE_URL}/assets/images/sp/afternoon/ogimage.png`}
      />
      <div className="lp_xmas2024" id="top">
        <section className="lp_kv">
          <figure className="imgWrapper u_pc">
            <AssetImage
              src="/assets/images/sp/christmas2024/mv.png"
              alt=""
              loading="lazy"
            />
          </figure>
          <figure className="imgWrapper u_sp">
            <AssetImage
              src="/assets/images/sp/christmas2024/mv_sp.png"
              alt=""
              loading="lazy"
            />
          </figure>
          {/* <div className="lp_kvTitle">
            <img src="/assets/images/sp/christmas2023/logo.png" className="u_pc" alt=""/>
            <img src="/assets/images/sp/christmas2023/logo_sp.png" className="u_sp" alt=""/>
          </div> */}
          <div className="scrolldown"><span>Scroll</span></div>
        </section>
        <section className="lp_sect01">
          <div className="lp_menu">
            <div className="star1-1 u_pc"></div>
            <div className="star1-2 u_pc"></div>
            <div className="lp_menuTextBox">
              <div className="lp_menuText">
                当ホテルで行われるクリスマススイーツコンテストで多彩な作品の中から選び抜かれた、<br />
                繊細で個性の光るデコレーションケーキの数々。<br />
                華やかなひとときをお過ごしいただきたいという想いとともに、ご家族や大切な方と忘れられないクリスマスを。
              </div>
              <div className="lp_menuBorderTextWrap">
                <div className="lp_menuBorderTextBox">
                  <ul className="lp_menuBorderText">
                    <li>ご予約期間：<br className="u_sp" />2024年10月1日(火) ～12月15日(日) 19:00</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="lp_menuList">
              <div className="lp_menuItem">
                <img src="/assets/images/sp/christmas2024/icon/restaurantbox.png" alt=""/>
              </div>
              <div className="lp_menuItem">
                <img src="/assets/images/sp/christmas2024/icon/planbox.png" alt=""/>
              </div>
              <div className="lp_menuItem">
                <img src="/assets/images/sp/christmas2024/icon/eventbox.png" alt=""/>
              </div>
              <a href="#takeout">
                <div className="lp_menuItem">
                  <img src="/assets/images/sp/christmas2024/icon/takeoutbox.png" alt=""/>
                </div>
              </a>
            </div>
          </div>
        </section>
        <section className="lp_sect02">
          <div className="lp_sect02Bg">
            <div className="star2-1 u_pc"></div>
            <div className="star2-2 u_pc"></div>
            <div className="star2-3 u_pc"></div>
            <div className="star2-4 u_pc"></div>
            <div className="star2-5 u_pc"></div>
            <div className="star2-6 u_pc"></div>
            <div className="star2-7 u_pc"></div>
            <div className="star2-8 u_pc"></div>
            <div className="star2-9 u_pc"></div>
            <div className="star2-10 u_pc"></div>
            <div className="star2-11 u_pc"></div>
            <div className="lp_sectTitleWrap" id="takeout">
              <img src="/assets/images/sp/christmas2024/title/takeouttitle.png" alt="take out title"/>
            </div>
            <div className="lp_sectSubTitle" id="christmascake">
              <img src="/assets/images/sp/christmas2024/title/cakesubtitle.png" alt="cake sub title"/>
            </div>
            <div className="lp_sectContents">
              <button
                onClick={openModal}
              >
                <div className="lp_sectContentsItem">
                  <img src="/assets/images/sp/christmas2024/cake/cake1-1.png" alt=""/>
                  <div className="lp_sectContentsItemBlock">
                    <div className="lp_sectContentsItemTitle">Sapin de Noël</div>
                    <div className="lp_sectContentsItemSubTitle">サパン ド ノエル</div>
                    <div className="lp_underbar"></div>
                    <div className="lp_underbar"></div>
                    <div className="lp_sectContentsTagBox">
                      <div className="lp_sectContentsTag">
                        濃厚なピスタチオガナッシュ
                      </div>
                      <div className="lp_sectContentsTag">
                        甘酸っぱいイチゴのジュレ
                      </div>
                      <div className="lp_sectContentsTag">
                        しっとり食感のショコラ生地
                      </div>
                    </div>
                    <div className="lp_sectContentsItemText">7,560円</div>
                    <div className="lp_sectContentsItemText">限定80個／要予約</div>
                  </div>
                </div>
              </button>
              <button
                onClick={openModal02}
              >
                <div className="lp_sectContentsItem">
                  <img src="/assets/images/sp/christmas2024/cake/cake4-2.png" alt=""/>
                  <div className="lp_sectContentsItemBlock">
                    <div className="lp_sectContentsItemTitle">Moment Joyeux</div>
                    <div className="lp_sectContentsItemSubTitle">モマン ジョワイユ</div>
                    <div className="lp_underbar"></div>
                    <div className="lp_underbar"></div>
                    <div className="lp_sectContentsTagBox">
                      <div className="lp_sectContentsTag">
                        風味豊かなアールグレイ
                      </div>
                      <div className="lp_sectContentsTag">
                        爽やかなオレンジのジュレ
                      </div>
                      <div className="lp_sectContentsTag">
                        フィヤンティーヌのサクサク食感
                      </div>
                    </div>
                    <div className="lp_sectContentsItemText">8,100円</div>
                    <div className="lp_sectContentsItemText">限定70個／要予約</div>
                  </div>
                </div>
              </button>
              <button
                onClick={openModal03}
              >
                <div className="lp_sectContentsItem">
                  <img src="/assets/images/sp/christmas2024/cake/cake2-1.png" alt=""/>
                  <div className="lp_sectContentsItemBlock">
                    <div className="lp_sectContentsItemTitle">Snowflake</div>
                    <div className="lp_sectContentsItemSubTitle">スノーフレーク</div>
                    <div className="lp_underbar"></div>
                    <div className="lp_underbar"></div>
                    <div className="lp_sectContentsTagBox">
                      <div className="lp_sectContentsTag">
                        芳醇なバニラの香り
                      </div>
                      <div className="lp_sectContentsTag">
                        マスカルポーネのコクとイチゴの爽やかな酸味
                      </div>
                      <div className="lp_sectContentsTag">
                        ザクザク食感のナッツ入りメレンゲ
                      </div>
                    </div>
                    <div className="lp_sectContentsItemText">7,020円</div>
                    <div className="lp_sectContentsItemText">限定80個／要予約</div>
                  </div>
                </div>
              </button>
              <button
                onClick={openModal04}
              >
                <div className="lp_sectContentsItem">
                  <img src="/assets/images/sp/christmas2024/cake/cake3-1.png" alt=""/>
                  <div className="lp_sectContentsItemBlock">
                    <div className="lp_sectContentsItemTitle--18">サンタクロースからのプレゼント</div>
                    <div className="margin16"></div>
                    <div className="lp_underbar"></div>
                    <div className="lp_underbar"></div>
                    <div className="lp_sectContentsTagBox">
                      <div className="lp_sectContentsTag">
                        爽やかな風味のベリージュレ
                      </div>
                      <div className="lp_sectContentsTag">
                        甘美な香りのトンカ豆のブリュレ
                      </div>
                      <div className="lp_sectContentsTag">
                        アーモンド香るショコラ生地
                      </div>
                    </div>
                    <div className="lp_sectContentsItemText">6,480円</div>
                    <div className="lp_sectContentsItemText">限定70個／要予約</div>
                  </div>
                </div>
              </button>
            </div>
            <div className="lp_sectContents2">
              <button
                onClick={openModal05}
              >
                <div className="lp_sectContentsItem">
                  <img src="/assets/images/sp/christmas2024/cake/cake5-1.png" alt=""/>
                  <div className="lp_sectContentsItemBlock">
                    <div className="lp_sectContentsItemTitle">Christmas Wishes</div>
                    <div className="lp_sectContentsItemSubTitle">クリスマスウィッシュ</div>
                    <div className="lp_underbar"></div>
                    <div className="lp_underbar"></div>
                    <div className="lp_sectContentsItemText">14,040円</div>
                    <div className="lp_sectContentsItemText">限定30個／要予約</div>
                  </div>
                </div>
              </button>
              <button
                onClick={openModal06}
              >
                <div className="lp_sectContentsItem">
                  <img src="/assets/images/sp/christmas2024/cake/cake6-1.png" alt=""/>
                  <div className="lp_sectContentsItemBlock">
                    <div className="lp_sectContentsItemTitle">Christmas Shortcake</div>
                    <div className="lp_sectContentsItemSubTitle">クリスマスショートケーキ</div>
                    <div className="lp_underbar"></div>
                    <div className="lp_underbar"></div>
                    <div className="lp_sectContentsItemText">
                      Lサイズ 6,480円<br />
                      Sサイズ 4,860円
                      </div>
                  </div>
                </div>
              </button>
              <button
                onClick={openModal07}
              >
                <div className="lp_sectContentsItem">
                  <img src="/assets/images/sp/christmas2024/cake/cake7-1.png" alt=""/>
                  <div className="lp_sectContentsItemBlock">
                    <div className="lp_sectContentsItemTitle">ヘキセンハウス</div>
                    <div className="lp_sectContentsItemSubTitle">お菓子の家</div>
                    <div className="lp_underbar"></div>
                    <div className="lp_underbar"></div>
                    <div className="lp_sectContentsItemText">7,560円</div>
                    <div className="lp_sectContentsItemText">限定40個／要予約</div>
                  </div>
                </div>
              </button>
              <button
                onClick={openModal08} 
              >
                <div className="lp_sectContentsItem">
                  <img src="/assets/images/sp/christmas2024/cake/cake8-1.png" alt=""/>
                  <div className="lp_sectContentsItemBlock">
                    <div className="lp_sectContentsItemTitle">Bûche de Noël</div>
                    <div className="lp_sectContentsItemSubTitle">ブッシュ・ド・ノエル</div>
                    <div className="lp_underbar"></div>
                    <div className="lp_underbar"></div>
                    <div className="lp_sectContentsItemText">4,860円</div>
                  </div>
                </div>
              </button>
              <button
                onClick={openModal09}
              >
                <div className="lp_sectContentsItem">
                  <img src="/assets/images/sp/christmas2024/cake/cake9-1.png" alt=""/>
                  <div className="lp_sectContentsItemBlock">
                    <div className="lp_sectContentsItemTitle">Jolie Noël</div>
                    <div className="lp_sectContentsItemSubTitle">ジョリー ノエル</div>
                    <div className="lp_underbar"></div>
                    <div className="lp_underbar"></div>
                    <div className="lp_sectContentsItemText">864円</div>
                  </div>
                </div>
              </button>
              <button
                onClick={openModal10}
              >
                <div className="lp_sectContentsItem">
                  <img src="/assets/images/sp/christmas2024/cake/cake10-1.png" alt=""/>
                  <div className="lp_sectContentsItemBlock">
                    <div className="lp_sectContentsItemTitle">Cassis Blanc</div>
                    <div className="lp_sectContentsItemSubTitle">カシス ブラン</div>
                    <div className="lp_underbar"></div>
                    <div className="lp_underbar"></div>
                    <div className="lp_sectContentsItemText">864円</div>
                  </div>
                </div>
              </button>
              <button
                onClick={openModal11}
              >
                <div className="lp_sectContentsItem">
                  <img src="/assets/images/sp/christmas2024/cake/cake11-1.png" alt=""/>
                  <div className="lp_sectContentsItemBlock">
                    <div className="lp_sectContentsItemTitle">Stollen</div>
                    <div className="lp_sectContentsItemSubTitle">シュトーレン</div>
                    <div className="lp_underbar"></div>
                    <div className="lp_underbar"></div>
                    <div className="lp_sectContentsItemText">
                      Lサイズ 2,916円<br />
                      Sサイズ 1,512円
                      </div>
                  </div>
                </div>
              </button>
              <button
                onClick={openModal12}
              >
                <div className="lp_sectContentsItem">
                  <img src="/assets/images/sp/christmas2024/cake/cake12-1.png" alt=""/>
                  <div className="lp_sectContentsItemBlock">
                    <div className="lp_sectContentsItemTitle">Brioche de Noël</div>
                    <div className="lp_sectContentsItemSubTitle">ブリオッシュ・ド・ノエル</div>
                    <div className="lp_underbar"></div>
                    <div className="lp_underbar"></div>
                    <div className="lp_sectContentsItemText">
                      Lサイズ 1,296円<br />
                      Sサイズ 432円
                      </div>
                  </div>
                </div>
              </button>
              <button
                onClick={openModal13}
              >
                <div className="lp_sectContentsItem">
                  <img src="/assets/images/sp/christmas2024/cake/cake13-1.png" alt=""/>
                  <div className="lp_sectContentsItemBlock">
                    <div className="lp_sectContentsItemTitle">Merry Bear</div>
                    <div className="lp_sectContentsItemSubTitle">クリスマスチョコレート メリーベア</div>
                    <div className="lp_underbar"></div>
                    <div className="lp_underbar"></div>
                    <div className="lp_sectContentsItemText">4,536円</div>
                    <div className="lp_sectContentsItemText">限定50個／要予約</div>
                  </div>
                </div>
              </button>
            </div>
            <div className="lp_sectSubTitle" id="homepartydish">
              <img src="/assets/images/sp/christmas2024/title/homepartydishsubtitle.png" alt="homepartydish sub title"/>
            </div>
            <div className="lp_sectContents2">
              <button
                onClick={openModal14}
              >
                <div className="lp_sectContentsItem">
                  <img src="/assets/images/sp/christmas2024/homeparty/homeparty1.png" alt=""/>
                  <div className="lp_sectContentsItemBlock">
                    <div className="lp_sectContentsItemTitleDishCourse">クリスマスパーティーボックス</div>
                    <div className="lp_underbar"></div>
                    <div className="lp_underbar"></div>
                    <div className="lp_sectContentsItemText">12,960円／要予約</div>
                  </div>
                </div>
              </button>
              <button
                onClick={openModal15}
              >
                <div className="lp_sectContentsItem">
                <img src="/assets/images/sp/christmas2024/homeparty/homeparty2.png" alt=""/>
                  <div className="lp_sectContentsItemBlock">
                    <div className="lp_sectContentsItemTitleDishCourse">ローストビーフ</div>
                    <div className="lp_underbar"></div>
                    <div className="lp_underbar"></div>
                    <div className="lp_sectContentsItemText">10,800円／要予約</div>
                  </div>
                </div>
              </button>
              <button
                onClick={openModal16}
              >
                <div className="lp_sectContentsItem">
                <img src="/assets/images/sp/christmas2024/homeparty/homeparty3.png" alt=""/>
                  <div className="lp_sectContentsItemBlock">
                    <div className="lp_sectContentsItemTitleDishCourse">ローストチキン</div>
                    <div className="lp_underbar"></div>
                    <div className="lp_underbar"></div>
                    <div className="lp_sectContentsItemText">6,480円／要予約</div>
                  </div>
                </div>
              </button>
              <button
                onClick={openModal17}
              >
                <div className="lp_sectContentsItem">
                <img src="/assets/images/sp/christmas2024/homeparty/homeparty4.png" alt=""/>
                  <div className="lp_sectContentsItemBlock">
                    <div className="lp_sectContentsItemTitleDishCourse">オードブル盛合せ</div>
                    <div className="lp_underbar"></div>
                    <div className="lp_underbar"></div>
                    <div className="lp_sectContentsItemText">7,560円／要予約</div>
                  </div>
                </div>
              </button>
              <button
                onClick={openModal18}
              >
                <div className="lp_sectContentsItem">
                <img src="/assets/images/sp/christmas2024/homeparty/homeparty5.png" alt=""/>
                  <div className="lp_sectContentsItemBlock">
                    <div className="lp_sectContentsItemTitleDishCourse">骨付き仔羊背肉ロースト</div>
                    <div className="lp_underbar"></div>
                    <div className="lp_underbar"></div>
                    <div className="lp_sectContentsItemText">4,320円／要予約</div>
                  </div>
                </div>
              </button>
              <button
                onClick={openModal19}
              >
                <div className="lp_sectContentsItem">
                <img src="/assets/images/sp/christmas2024/homeparty/homeparty6.png" alt=""/>
                  <div className="lp_sectContentsItemBlock">
                    <div className="lp_sectContentsItemTitleDishCourse">ビーフシチュー</div>
                    <div className="lp_underbar"></div>
                    <div className="lp_underbar"></div>
                    <div className="lp_sectContentsItemText">3,240円</div>
                  </div>
                </div>
              </button>
              <button
                onClick={openModal20}
              >
                <div className="lp_sectContentsItem">
                <img src="/assets/images/sp/christmas2024/homeparty/homeparty7.png" alt=""/>
                  <div className="lp_sectContentsItemBlock">
                    <div className="lp_sectContentsItemTitleDishCourse">鶏もも焼き</div>
                    <div className="lp_underbar"></div>
                    <div className="lp_underbar"></div>
                    <div className="lp_sectContentsItemText">1本　1,080円</div>
                  </div>
                </div>
              </button>
            </div>
            <div className="lp_sectSubTitle" id="coursemenu">
              <img src="/assets/images/sp/christmas2024/title/coursemenusubtitle.png" alt="coursemenu sub title"/>
            </div>
            <div className="lp_sectContents3">
              <button
                onClick={openModal21}
              >
                <div className="lp_sectContentsItem">
                  <img src="/assets/images/sp/christmas2024/course/course1.png" alt=""/>
                  <div className="lp_sectContentsItemBlock">
                    <div className="lp_sectContentsItemTitleDishCourse">コースメニュー <br className='u_sp'/>-French-</div>
                    <div className="lp_underbar"></div>
                    <div className="lp_underbar"></div>
                    <div className="lp_sectContentsItemText">14,040円／要予約</div>
                  </div>
                </div>
              </button>
              <button
                onClick={openModal22}
              >
                <div className="lp_sectContentsItem">
                <img src="/assets/images/sp/christmas2024/course/course2.png" alt=""/>
                  <div className="lp_sectContentsItemBlock">
                    <div className="lp_sectContentsItemTitleDishCourse">コースメニュー <br className='u_sp'/>-Chinese-</div>
                    <div className="lp_underbar"></div>
                    <div className="lp_underbar"></div>
                    <div className="lp_sectContentsItemText">8,640円／要予約</div>
                  </div>
                </div>
              </button>
            </div>
          </div>
          <div className="lp_sectButtonWrap">
            <a href="https://www.tablecheck.com/shops/royalparkhotel-tokyo-iki/reserve?menu_lists[]=66b0724a1f78308f6f9a5b20&num_people=1" target="_blank">
              <div className="lp_sectButton">
              テイクアウト商品のご予約はこちら →
              </div>
            </a>
          </div>
        </section>
        <section className="lp_sect03" id="">
          <div className="star3-1 u_pc"></div>
          <div className="star3-2 u_pc"></div>
          <div className="star3 u_sp"></div>
          <div className="lp_attentionWrap">
            <div className="lp_attentionTextBox">
              <ul className="lp_attentionText">
                <li>※料金には消費税（8％）が含まれます。</li>
                <li>※お持帰りのみの販売となります。</li>
                <li>※掲載の写真は実際と異なる場合がございます。</li>
              </ul>
            </div>
          </div>
          <a href="#top">
            <div className="lp_ankerLinkTopWrap">
              <div className="lp_ankerLinkTop"></div>
            </div>
          </a>
        </section>
        <section className="lp_comingsoonWrap">
          <img className="u_pc" src="/assets/images/sp/christmas2024/comingsoon_bg.png" alt=""/>
          <img className="u_sp" src="/assets/images/sp/christmas2024/comingsoon_bg_sp.png" alt=""/>
          <div className="lp_comingsoonTitle">
            <div className="lp_comingsoonText">クリスマスならではのレストランメニュー・ご宿泊プラン・イベントをご準備しております。</div>
          </div>
        </section>
        <section className="lp_snsBlock">
          <a href="https://www.instagram.com/royalparkhotel.tokyo/" target="_blank">
            <div className="lp_snsBlockSubTitle">\ Follow me /</div>
            <div className="lp_snsBlockTitle">Instagram</div>
            <img src="/assets/images/sp/christmas2024/icon/Instagram_icon.png" alt=""/>
            <div className="lp_snsBlockAddress u_pc">@royalparkhotel.tokyo</div>
            <div className="lp_snsBlockText">
              ロイヤルパークホテルのご案内や、イベント、最新情報をご紹介！<br className="u_pc" />
              皆様からのたくさんのフォローをお待ちしております。
            </div>
            <div className="lp_snsBlockAddress u_sp">@royalparkhotel.tokyo</div>
          </a>
        </section>
        <div className="lp_xmasFooter disnon u_sp">
          <ul>
            <li>
              <a href="#restaurant">
                <div className="lp_xmasFooterImg">
                  <AssetImage
                    src="/assets/images/sp/christmas2023/icon/icon_restaurant.png"
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="lp_xmasFooterText">レストラン</div>
              </a>
            </li>
            <li>
              <a href="#stay">
                <div className="lp_xmasFooterImg">
                  <AssetImage
                    src="/assets/images/sp/christmas2023/icon/icon_stay.png"
                    alt=""
                    loading="lazy"
                  />
                </div>
              <div className="lp_xmasFooterText">ステイ</div>
              </a>
            </li>
            <li>
              <a href="#event">
                <div className="lp_xmasFooterImg">
                  <AssetImage
                    src="/assets/images/sp/christmas2023/icon/icon_event.png"
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="lp_xmasFooterText">イベント</div>
              </a>
            </li>
            <li>
              <a href="#christmascake">
                <div className="lp_xmasFooterImg">
                  <AssetImage
                    src="/assets/images/sp/christmas2023/icon/icon_take.png"
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="lp_xmasFooterText">テイクアウト</div>
              </a>
            </li>
          </ul>
        </div>
        {/* modal */}
        {modalFlag && (
          <CLpModal onClose={openModal} exClass="p_access_modal">
            <div className="c_lpModal">
              <div className="c_lpModalTitle u_sp">
                Sapin de Noël<br />
                <div className="c_lpModalTitleBold">
                  サパン ド ノエル
                </div>
              </div>
              <div className="c_lpModalImg u_pc">
                <img src="/assets/images/sp/christmas2024/cake/cake1-1.png" alt=""/>
                {/* <Swiper
                  loop={false}
                  spaceBetween={0}
                  navigation={false}
                  thumbs={{ swiper: thumbsSwiper4 && !thumbsSwiper4.destroyed ? thumbsSwiper4 : null}}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper4"
                >
                  <SwiperSlide>
                    <img src="/assets/images/sp/christmas2023/cake.png" alt=""/>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/christmas2023/cake1-2.png" alt=""/>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/christmas2023/cake1-3.png" alt=""/>
                  </SwiperSlide>
                </Swiper>
                <Swiper
                  onSwiper={setThumbsSwiper4}
                  loop={false}
                  spaceBetween={10}
                  slidesPerView={3}
                  breakpoints={{
                    640: {
                      slidesPerView: 5,
                      spaceBetween: 10
                    }
                  }}
                  freeMode={true}
                  watchSlidesProgress={true}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper3"
                >
                  <SwiperSlide>
                    <img src="/assets/images/sp/christmas2023/cake.png" alt=""/>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/christmas2023/cake1-2.png" alt=""/>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/christmas2023/cake1-3.png" alt=""/>
                  </SwiperSlide>
                </Swiper> */}
              </div>
              <div className="c_lpModalImg u_sp">
                <img src="/assets/images/sp/christmas2024/cake/cake1-1.png" alt=""/>
                {/* <Swiper
                  loop={false}
                  spaceBetween={0}
                  navigation={false}
                  thumbs={{ swiper: thumbsSwiper4 && !thumbsSwiper4.destroyed ? thumbsSwiper4 : null}}
                  className="mySwiper4"
                  pagination={{
                    clickable: true,
                  }}
                  modules={[FreeMode, Pagination, Thumbs]}
                >
                  <SwiperSlide>
                    <img src="/assets/images/sp/christmas2023/cake.png" alt=""/>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/christmas2023/cake1-2.png" alt=""/>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/christmas2023/cake1-3.png" alt=""/>
                  </SwiperSlide>
                </Swiper> */}
              </div>
              <div className="c_lpModalTextWrap">
                <div>
                  <div className="c_lpModalTitle u_pc">
                    Sapin de Noël<br />
                    <div className="c_lpModalTitleBold">
                      サパン ド ノエル
                    </div>
                    <div className="lp_underbar u_pc"></div>
                    <div className="lp_underbar u_pc"></div>
                  </div>
                  <div className="c_lpModalText">
                    ピスタチオガナッシュを重ね、黄色のチョコレートリボンを添えたクリスマスツリーをイメージしたケーキ。濃厚なピスタチオ、甘酸っぱいイチゴ、アーモンド入りのショコラ生地が調和した上品な味わいに仕上げました。
                  </div>
                  <ul>
                    <li className="c_lpModalTextContentsList">
                      <div className="c_lpModalTextContentsListTextBold">料金</div>
                      <div className="c_lpModalTextContentsListText">
                        7,560円
                      </div>
                    </li>
                    <li className="c_lpModalTextContentsList">
                      <div className="c_lpModalTextContentsListTextBold">限定数</div>
                      <div className="c_lpModalTextContentsListText">
                        80個／要予約
                      </div>
                    </li>
                    <li className="c_lpModalTextContentsList">
                      <div className="c_lpModalTextContentsListTextBold">サイズ</div>
                      <div className="c_lpModalTextContentsListText">
                        4～6名様分<br />
                        直径約15.5cm×高さ約15cm
                      </div>
                    </li>
                  </ul>
                  <div className="c_lpModalAttention">
                    ※ 限定商品のため、販売終了の際はご容赦ください。
                  </div>
                </div>
                <a href="https://www.tablecheck.com/shops/royalparkhotel-tokyo-iki/reserve?menu_lists[]=66b0724a1f78308f6f9a5b20&num_people=1" target="_blank">
                  <div className="c_lpModalButtonChristmas">
                    ご予約はこちら →
                  </div>
                </a>
              </div>
            </div>
          </CLpModal>
        )}
        {modalFlag02 && (
          <CLpModal onClose={openModal02} exClass="p_access_modal">
            <div className="c_lpModal">
              <div className="c_lpModalTitle u_sp">
                Moment Joyeux<br />
                <div className="c_lpModalTitleBold">
                  モマン ジョワイユ
                </div>
              </div>
              <div className="c_lpModalImg u_pc">
                <img src="/assets/images/sp/christmas2024/cake/cake4-2.png" alt=""/>
                {/* <Swiper
                  loop={false}
                  spaceBetween={0}
                  navigation={false}
                  thumbs={{ swiper: thumbsSwiper4 && !thumbsSwiper4.destroyed ? thumbsSwiper4 : null}}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper4"
                >
                  <SwiperSlide>
                    <img src="/assets/images/sp/christmas2023/cake4.png" alt=""/>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/christmas2023/cake4-2.png" alt=""/>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/christmas2023/cake4-3.png" alt=""/>
                  </SwiperSlide>
                </Swiper>
                <Swiper
                  onSwiper={setThumbsSwiper4}
                  loop={false}
                  spaceBetween={10}
                  slidesPerView={3}
                  breakpoints={{
                    640: {
                      slidesPerView: 5,
                      spaceBetween: 10
                    }
                  }}
                  freeMode={true}
                  watchSlidesProgress={true}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper3"
                >
                  <SwiperSlide>
                    <img src="/assets/images/sp/christmas2023/cake4.png" alt=""/>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/christmas2023/cake4-2.png" alt=""/>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/christmas2023/cake4-3.png" alt=""/>
                  </SwiperSlide>
                </Swiper> */}
              </div>
              <div className="c_lpModalImg u_sp">
                <img src="/assets/images/sp/christmas2024/cake/cake4-2.png" alt=""/>
                {/* <Swiper
                  loop={false}
                  spaceBetween={0}
                  navigation={false}
                  thumbs={{ swiper: thumbsSwiper4 && !thumbsSwiper4.destroyed ? thumbsSwiper4 : null}}
                  className="mySwiper4"
                  pagination={{
                    clickable: true,
                  }}
                  modules={[FreeMode, Pagination, Thumbs]}
                >
                  <SwiperSlide>
                    <img src="/assets/images/sp/christmas2023/cake4.png" alt=""/>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/christmas2023/cake4-2.png" alt=""/>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/christmas2023/cake4-3.png" alt=""/>
                  </SwiperSlide>
                </Swiper> */}
              </div>
              <div className="c_lpModalTextWrap">
                <div>
                  <div className="c_lpModalTitle u_pc">
                    Moment Joyeux<br />
                    <div className="c_lpModalTitleBold">
                      モマン ジョワイユ
                    </div>
                    <div className="lp_underbar"></div>
                    <div className="lp_underbar"></div>
                  </div>
                  <div className="c_lpModalText">
                    アールグレイとオレンジジュレのハーモニーをご堪能いただける大人のムースショコラ。香ばしいダクワーズノワゼットやフィヤンティーヌの食感が魅力で、歯車時計の文字盤を表現したチョコレート細工はクリスマスの幸せな時間を過ごしていただきたいという想いを込めました。
                  </div>
                  <ul>
                    <li className="c_lpModalTextContentsList">
                      <div className="c_lpModalTextContentsListTextBold">料金</div>
                      <div className="c_lpModalTextContentsListText">
                        8,100円
                      </div>
                    </li>
                    <li className="c_lpModalTextContentsList">
                      <div className="c_lpModalTextContentsListTextBold">限定数</div>
                      <div className="c_lpModalTextContentsListText">
                        70個／要予約
                      </div>
                    </li>
                    <li className="c_lpModalTextContentsList">
                      <div className="c_lpModalTextContentsListTextBold">サイズ</div>
                      <div className="c_lpModalTextContentsListText">
                        4～6名様分<br />
                        直径約18cm×高さ約7.5cm
                      </div>
                    </li>
                  </ul>
                  <div className="c_lpModalAttention">
                    ※ 限定商品のため、販売終了の際はご容赦ください。
                  </div>
                </div>
                <a href="https://www.tablecheck.com/shops/royalparkhotel-tokyo-iki/reserve?menu_lists[]=66b0724a1f78308f6f9a5b20&num_people=1" target="_blank">
                  <div className="c_lpModalButtonChristmas">
                    ご予約はこちら →
                  </div>
                </a>
              </div>
            </div>
          </CLpModal>
        )}
        {modalFlag03 && (
          <CLpModal onClose={openModal03} exClass="p_access_modal">
            <div className="c_lpModal">
              <div className="c_lpModalTitle u_sp">
                Snowflake<br />
                <div className="c_lpModalTitleBold">
                  スノーフレーク
                </div>
              </div>
              <div className="c_lpModalImg u_pc">
                <img src="/assets/images/sp/christmas2024/cake/cake2-1.png" alt=""/>
                {/* <Swiper
                  loop={false}
                  spaceBetween={0}
                  navigation={false}
                  thumbs={{ swiper: thumbsSwiper4 && !thumbsSwiper4.destroyed ? thumbsSwiper4 : null}}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper4"
                >
                  <SwiperSlide>
                    <img src="/assets/images/sp/christmas2023/cake2.png" alt=""/>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/christmas2023/cake2-2.png" alt=""/>
                  </SwiperSlide>
                </Swiper>
                <Swiper
                  onSwiper={setThumbsSwiper4}
                  loop={false}
                  spaceBetween={10}
                  slidesPerView={3}
                  breakpoints={{
                    640: {
                      slidesPerView: 5,
                      spaceBetween: 10
                    }
                  }}
                  freeMode={true}
                  watchSlidesProgress={true}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper3"
                >
                  <SwiperSlide>
                    <img src="/assets/images/sp/christmas2023/cake2.png" alt=""/>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/christmas2023/cake2-2.png" alt=""/>
                  </SwiperSlide>
                </Swiper> */}
              </div>
              <div className="c_lpModalImg u_sp">
                <img src="/assets/images/sp/christmas2024/cake/cake2-1.png" alt=""/>
                {/* <Swiper
                  loop={false}
                  spaceBetween={0}
                  navigation={false}
                  thumbs={{ swiper: thumbsSwiper4 && !thumbsSwiper4.destroyed ? thumbsSwiper4 : null}}
                  className="mySwiper4"
                  pagination={{
                    clickable: true,
                  }}
                  modules={[FreeMode, Pagination, Thumbs]}
                >
                  <SwiperSlide>
                    <img src="/assets/images/sp/christmas2023/cake2.png" alt=""/>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/christmas2023/cake2-2.png" alt=""/>
                  </SwiperSlide>
                </Swiper> */}
              </div>
              <div className="c_lpModalTextWrap">
                <div>
                  <div className="c_lpModalTitle u_pc">
                    Snowflake<br />
                    <div className="c_lpModalTitleBold">
                      スノーフレーク
                    </div>
                    <div className="lp_underbar"></div>
                    <div className="lp_underbar"></div>
                  </div>
                  <div className="c_lpModalText">
                    雪の結晶を表現したチョコレート細工と、赤と白のコントラストが印象的なケーキ。バニラとマスカルポーネのムース、イチゴのジュレ、ピスタチオのガナッシュ、ホワイトチョコレートを組合せ、濃厚さと甘酸っぱさ、軽やかなメレンゲの食感をお楽しみください。
                  </div>
                  <ul>
                    <li className="c_lpModalTextContentsList">
                      <div className="c_lpModalTextContentsListTextBold">料金</div>
                      <div className="c_lpModalTextContentsListText">
                        7,020円
                      </div>
                    </li>
                    <li className="c_lpModalTextContentsList">
                      <div className="c_lpModalTextContentsListTextBold">限定数</div>
                      <div className="c_lpModalTextContentsListText">
                        80個／要予約
                      </div>
                    </li>
                    <li className="c_lpModalTextContentsList">
                      <div className="c_lpModalTextContentsListTextBold">サイズ</div>
                      <div className="c_lpModalTextContentsListText">
                        4～6名様分<br />
                        直径約17cm×高さ約12cm
                      </div>
                    </li>
                  </ul>
                  <div className="c_lpModalAttention">
                    ※ 限定商品のため、販売終了の際はご容赦ください。
                  </div>
                </div>
                <a href="https://www.tablecheck.com/shops/royalparkhotel-tokyo-iki/reserve?menu_lists[]=66b0724a1f78308f6f9a5b20&num_people=1" target="_blank">
                  <div className="c_lpModalButtonChristmas">
                    ご予約はこちら →
                  </div>
                </a>
              </div>
            </div>
          </CLpModal>
        )}
        {modalFlag04 && (
          <CLpModal onClose={openModal04} exClass="p_access_modal">
            <div className="c_lpModal">
              <div className="c_lpModalTitle--18 u_sp">
                サンタクロースからのプレゼント
              </div>
              <div className="c_lpModalImg u_pc">
                <img src="/assets/images/sp/christmas2024/cake/cake3-1.png" alt=""/>
                {/* <Swiper
                  loop={false}
                  spaceBetween={0}
                  navigation={false}
                  thumbs={{ swiper: thumbsSwiper4 && !thumbsSwiper4.destroyed ? thumbsSwiper4 : null}}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper4"
                >
                  <SwiperSlide>
                    <img src="/assets/images/sp/christmas2023/cake3.png" alt=""/>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/christmas2023/cake3-2.png" alt=""/>
                  </SwiperSlide>
                </Swiper>
                <Swiper
                  onSwiper={setThumbsSwiper4}
                  loop={false}
                  spaceBetween={10}
                  slidesPerView={3}
                  breakpoints={{
                    640: {
                      slidesPerView: 5,
                      spaceBetween: 10
                    }
                  }}
                  freeMode={true}
                  watchSlidesProgress={true}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper3"
                >
                  <SwiperSlide>
                    <img src="/assets/images/sp/christmas2023/cake3.png" alt=""/>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/christmas2023/cake3-2.png" alt=""/>
                  </SwiperSlide>
                </Swiper> */}
              </div>
              <div className="c_lpModalImg u_sp">
                <img src="/assets/images/sp/christmas2024/cake/cake3-1.png" alt=""/>
                {/* <Swiper
                  loop={false}
                  spaceBetween={0}
                  navigation={false}
                  thumbs={{ swiper: thumbsSwiper4 && !thumbsSwiper4.destroyed ? thumbsSwiper4 : null}}
                  className="mySwiper4"
                  pagination={{
                    clickable: true,
                  }}
                  modules={[FreeMode, Pagination, Thumbs]}
                >
                  <SwiperSlide>
                    <img src="/assets/images/sp/christmas2023/cake3.png" alt=""/>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/christmas2023/cake3-2.png" alt=""/>
                  </SwiperSlide>
                </Swiper> */}
              </div>
              <div className="c_lpModalTextWrap">
                <div>
                  <div className="c_lpModalTitle u_pc">
                    サンタクロースからのプレゼント
                    <div className="lp_underbar"></div>
                    <div className="lp_underbar"></div>
                  </div>
                  <div className="c_lpModalText">
                    イチゴやグリオットチェリーのムースにトンカ豆のブリュレや酸味のベリージュレを包んだ愛らしいピンク色のムース。サンタクロースと雪の結晶が描かれ、チョコレート細工のプレゼント袋に可愛いチョコレートが仕込まれた遊び心あふれる作品です。
                  </div>
                  <ul>
                    <li className="c_lpModalTextContentsList">
                      <div className="c_lpModalTextContentsListTextBold">料金</div>
                      <div className="c_lpModalTextContentsListText">
                        6,480円
                      </div>
                    </li>
                    <li className="c_lpModalTextContentsList">
                      <div className="c_lpModalTextContentsListTextBold">限定数</div>
                      <div className="c_lpModalTextContentsListText">
                        70個／要予約
                      </div>
                    </li>
                    <li className="c_lpModalTextContentsList">
                      <div className="c_lpModalTextContentsListTextBold">サイズ</div>
                      <div className="c_lpModalTextContentsListText">
                        2～4名様分<br />
                        直径約15.5cm×高さ約5cm<br />
                        （飾りを含む場合約12cm）
                      </div>
                    </li>
                  </ul>
                  <div className="c_lpModalAttention">
                    ※ 限定商品のため、販売終了の際はご容赦ください。
                  </div>
                </div>
                <a href="https://www.tablecheck.com/shops/royalparkhotel-tokyo-iki/reserve?menu_lists[]=66b0724a1f78308f6f9a5b20&num_people=1" target="_blank">
                  <div className="c_lpModalButtonChristmas">
                    ご予約はこちら →
                  </div>
                </a>
              </div>
            </div>
          </CLpModal>
        )}
        {modalFlag05 && (
          <CLpModal onClose={openModal05} exClass="p_access_modal">
            <div className="c_lpModal">
              <div className="c_lpModalTitle u_sp">
                Christmas Wishes<br />
                <div className="c_lpModalTitleBold">
                クリスマスウィッシュ
                </div>
              </div>
              <div className="c_lpModalImg u_pc">
                <img src="/assets/images/sp/christmas2024/cake/cake5-1.png" alt=""/>
              </div>
              <div className="c_lpModalImg u_sp">
                <img src="/assets/images/sp/christmas2024/cake/cake5-1.png" alt=""/>
              </div>
              <div className="c_lpModalTextWrap">
                <div>
                  <div className="c_lpModalTitle u_pc">
                    Christmas Wishes<br />
                  <div className="c_lpModalTitleBold">
                    クリスマスウィッシュ
                  </div>
                    <div className="lp_underbar"></div>
                    <div className="lp_underbar"></div>
                  </div>
                  <div className="c_lpModalText">
                    きめ細やかなスポンジと甘さ控えめの生クリームのショートケーキにイチゴをデコレーション。プレゼントボックスに入ったくまが愛らしい大人数様用のパーティーケーキです。
                  </div>
                  <ul>
                    <li className="c_lpModalTextContentsList">
                      <div className="c_lpModalTextContentsListTextBold">料金</div>
                      <div className="c_lpModalTextContentsListText">
                      14,040円
                      </div>
                    </li>
                    <li className="c_lpModalTextContentsList">
                      <div className="c_lpModalTextContentsListTextBold">限定数</div>
                      <div className="c_lpModalTextContentsListText">
                      30個／要予約
                      </div>
                    </li>
                    <li className="c_lpModalTextContentsList">
                      <div className="c_lpModalTextContentsListTextBold">サイズ</div>
                      <div className="c_lpModalTextContentsListText">
                      10～12名様分
                      <br />
                      直径約22 cm×高さ約17cm
                      </div>
                    </li>
                  </ul>
                  <div className="c_lpModalAttention">
                    ※ 限定商品のため、販売終了の際はご容赦ください。
                  </div>
                  <a href="https://www.tablecheck.com/shops/royalparkhotel-tokyo-iki/reserve?menu_lists[]=66b0724a1f78308f6f9a5b20&num_people=1" target="_blank">
                    <div className="c_lpModalButtonChristmas">
                      ご予約はこちら →
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </CLpModal>
        )}
        {modalFlag06 && (
          <CLpModal onClose={openModal06} exClass="p_access_modal">
          <div className="c_lpModal">
            <div className="c_lpModalTitle u_sp">
            Christmas Shortcake<br />
              <div className="c_lpModalTitleBold">
              クリスマスショートケーキ
              </div>
            </div>
            <div className="c_lpModalImg u_pc">
              <img src="/assets/images/sp/christmas2024/cake/cake6-1.png" alt=""/>
            </div>
            <div className="c_lpModalImg u_sp">
              <img src="/assets/images/sp/christmas2024/cake/cake6-1.png" alt=""/>
            </div>
            <div className="c_lpModalTextWrap">
              <div>
                <div className="c_lpModalTitle u_pc">
                Christmas Shortcake<br />
                <div className="c_lpModalTitleBold">
                クリスマスショートケーキ
                </div>
                  <div className="lp_underbar"></div>
                  <div className="lp_underbar"></div>
                </div>
                <div className="c_lpModalText">
                ラズベリーのシロップを入れて焼き上げたしっとりきめ細やかなスポンジで、たっぷりの生クリームとイチゴをサンドした軽やかな口あたりのショートケーキです。
                </div>
                <ul>
                  <li className="c_lpModalTextContentsList">
                    <div className="c_lpModalTextContentsListTextBold">料金</div>
                    <div className="c_lpModalTextContentsListText">
                    Lサイズ 6,480円<br />
                    Sサイズ 4,860円
                    </div>
                  </li>
                  <li className="c_lpModalTextContentsList">
                    <div className="c_lpModalTextContentsListTextBold">サイズ</div>
                    <div className="c_lpModalTextContentsListText">
                    Lサイズ 4～6名様分<br />
                    縦約11.5cm×横約16.5cm×高さ約10cm<br />
                    Sサイズ 2～4名様分<br />
                    縦約11cm×横約11cm×高さ約10cm
                    </div>
                  </li>
                </ul>
                <div className="c_lpModalAttention">
                  ※ 2024年12月21日（土）～12月25日（水）1Fスイーツ＆ベーカリー 粋にて店頭販売。
                  <br />
                  ※ 写真はLサイズです。
                </div>
                <div className="c_lpModalAttention">
                  
                </div>
                <a href="https://www.tablecheck.com/shops/royalparkhotel-tokyo-iki/reserve?menu_lists[]=66b0724a1f78308f6f9a5b20&num_people=1" target="_blank">
                  <div className="c_lpModalButtonChristmas">
                    ご予約はこちら →
                  </div>
                </a>
              </div>
            </div>
          </div>
        </CLpModal>
        )}
        {modalFlag07 && (
          <CLpModal onClose={openModal07} exClass="p_access_modal">
          <div className="c_lpModal">
            <div className="c_lpModalTitle u_sp">
            ヘキセンハウス<br />
              <div className="c_lpModalTitleBold">
              お菓子の家
              </div>
            </div>
            <div className="c_lpModalImg u_pc">
              <img src="/assets/images/sp/christmas2024/cake/cake7-1.png" alt=""/>
            </div>
            <div className="c_lpModalImg u_sp">
              <img src="/assets/images/sp/christmas2024/cake/cake7-1.png" alt=""/>
            </div>
            <div className="c_lpModalTextWrap">
              <div>
                <div className="c_lpModalTitle u_pc">
                ヘキセンハウス<br />
                <div className="c_lpModalTitleBold">
                お菓子の家
                </div>
                  <div className="lp_underbar"></div>
                  <div className="lp_underbar"></div>
                </div>
                <div className="c_lpModalText">
                バニラクッキーをベースに、胡桃と歯ざわりの良いクロッカンを組合せました。飾って、食べてお楽しみください。
                </div>
                <ul>
                  <li className="c_lpModalTextContentsList">
                    <div className="c_lpModalTextContentsListTextBold">料金</div>
                    <div className="c_lpModalTextContentsListText">
                    7,560円
                    </div>
                  </li>
                  <li className="c_lpModalTextContentsList">
                    <div className="c_lpModalTextContentsListTextBold">限定数</div>
                    <div className="c_lpModalTextContentsListText">
                    40個／要予約
                    </div>
                  </li>
                  <li className="c_lpModalTextContentsList">
                    <div className="c_lpModalTextContentsListTextBold">サイズ</div>
                    <div className="c_lpModalTextContentsListText">
                    縦約17.5cm×横約19cm×高さ約19cm
                    </div>
                  </li>
                </ul>
                <div className="c_lpModalAttention">
                  ※ 限定商品のため、販売終了の際はご容赦ください。<br/>
                  ※ お受取り日の5日前までにご予約ください。<br/>
                  ※ 2024年12月1日（日）よりご購入いただけます。
                </div>
                <a href="https://www.tablecheck.com/shops/royalparkhotel-tokyo-iki/reserve?menu_lists[]=66b0724a1f78308f6f9a5b20&num_people=1" target="_blank">
                  <div className="c_lpModalButtonChristmas">
                    ご予約はこちら →
                  </div>
                </a>
              </div>
            </div>
          </div>
        </CLpModal>
        )}
        {modalFlag08 && (
          <CLpModal onClose={openModal08} exClass="p_access_modal">
          <div className="c_lpModal">
            <div className="c_lpModalTitle u_sp">
            Bûche de Noël<br />
              <div className="c_lpModalTitleBold">
              ブッシュ・ド・ノエル
              </div>
            </div>
            <div className="c_lpModalImg u_pc">
              <img src="/assets/images/sp/christmas2024/cake/cake8-1.png" alt=""/>
            </div>
            <div className="c_lpModalImg u_sp">
              <img src="/assets/images/sp/christmas2024/cake/cake8-1.png" alt=""/>
            </div>
            <div className="c_lpModalTextWrap">
              <div>
                <div className="c_lpModalTitle u_pc">
                Bûche de Noël<br />
                <div className="c_lpModalTitleBold">
                ブッシュ・ド・ノエル
                </div>
                  <div className="lp_underbar"></div>
                  <div className="lp_underbar"></div>
                </div>
                <div className="c_lpModalText">
                チョコレートのグラサージュが映える華やかなブッシュ・ド・ノエル。ラズベリーとチョコレートの相性の良い組合せが楽しめます。
                </div>
                <ul>
                  <li className="c_lpModalTextContentsList">
                    <div className="c_lpModalTextContentsListTextBold">料金</div>
                    <div className="c_lpModalTextContentsListText">
                    4,860円
                    </div>
                  </li>
                  <li className="c_lpModalTextContentsList">
                    <div className="c_lpModalTextContentsListTextBold">サイズ</div>
                    <div className="c_lpModalTextContentsListText">
                    4～6名様分<br />
                    縦約17cm×横約6.5cm×高さ約6.5cm
                    </div>
                  </li>
                </ul>
                <div className="c_lpModalAttention">
                  ※ 2024年12月21日（土）～12月25日（水）1Fスイーツ＆ベーカリー 粋にて店頭販売。
                </div>
                <div className="c_lpModalAttention">
                  
                </div>
                <a href="https://www.tablecheck.com/shops/royalparkhotel-tokyo-iki/reserve?menu_lists[]=66b0724a1f78308f6f9a5b20&num_people=1" target="_blank">
                  <div className="c_lpModalButtonChristmas">
                    ご予約はこちら →
                  </div>
                </a>
              </div>
            </div>
          </div>
        </CLpModal>
        )}
        {modalFlag09 && (
          <CLpModal onClose={openModal09} exClass="p_access_modal">
          <div className="c_lpModal">
            <div className="c_lpModalTitle u_sp">
            Jolie Noël<br />
              <div className="c_lpModalTitleBold">
              ジョリー ノエル
              </div>
            </div>
            <div className="c_lpModalImg u_pc">
              <img src="/assets/images/sp/christmas2024/cake/cake9-1.png" alt=""/>
            </div>
            <div className="c_lpModalImg u_sp">
              <img src="/assets/images/sp/christmas2024/cake/cake9-1.png" alt=""/>
            </div>
            <div className="c_lpModalTextWrap">
              <div>
                <div className="c_lpModalTitle u_pc">
                Jolie Noël<br />
                <div className="c_lpModalTitleBold">
                ジョリー ノエル
                </div>
                  <div className="lp_underbar"></div>
                  <div className="lp_underbar"></div>
                </div>
                <div className="c_lpModalText">
                濃厚で華やかな香りのライチとホワイトチョコのムースにラズベリーとピスタチオビスキュイを組合せました。艶やかな赤いグラサージュがクリスマスを彩ります。
                </div>
                <ul>
                  <li className="c_lpModalTextContentsList">
                    <div className="c_lpModalTextContentsListTextBold">料金</div>
                    <div className="c_lpModalTextContentsListText">
                    864円
                    </div>
                  </li>
                  <li className="c_lpModalTextContentsList">
                    <div className="c_lpModalTextContentsListTextBold">サイズ</div>
                    <div className="c_lpModalTextContentsListText">
                    1名様分
                    </div>
                  </li>
                </ul>
                <div className="c_lpModalAttention">
                  ※ 2024年12月21日（土）～12月25日（水）1Fスイーツ＆ベーカリー 粋にて店頭販売。
                </div>
                <div className="c_lpModalAttention">
                  
                </div>
                <a href="https://www.tablecheck.com/shops/royalparkhotel-tokyo-iki/reserve?menu_lists[]=66b0724a1f78308f6f9a5b20&num_people=1" target="_blank">
                  <div className="c_lpModalButtonChristmas">
                    ご予約はこちら →
                  </div>
                </a>
              </div>
            </div>
          </div>
        </CLpModal>
        )}
        {modalFlag10 && (
          <CLpModal onClose={openModal10} exClass="p_access_modal">
          <div className="c_lpModal">
            <div className="c_lpModalTitle u_sp">
            Cassis Blanc<br />
              <div className="c_lpModalTitleBold">
              カシス ブラン
              </div>
            </div>
            <div className="c_lpModalImg u_pc">
              <img src="/assets/images/sp/christmas2024/cake/cake10-1.png" alt=""/>
            </div>
            <div className="c_lpModalImg u_sp">
              <img src="/assets/images/sp/christmas2024/cake/cake10-1.png" alt=""/>
            </div>
            <div className="c_lpModalTextWrap">
              <div>
                <div className="c_lpModalTitle u_pc">
                Cassis Blanc<br />
                <div className="c_lpModalTitleBold">
                カシス ブラン
                </div>
                  <div className="lp_underbar"></div>
                  <div className="lp_underbar"></div>
                </div>
                <div className="c_lpModalText">
                甘酸っぱいカシスのジュレをまろやかなフロマージュムースに閉じ込めました。土台のクッキー生地がアクセントになった、食感の違いをお楽しみいただけるケーキです。
                </div>
                <ul>
                  <li className="c_lpModalTextContentsList">
                    <div className="c_lpModalTextContentsListTextBold">料金</div>
                    <div className="c_lpModalTextContentsListText">
                    864円
                    </div>
                  </li>
                  <li className="c_lpModalTextContentsList">
                    <div className="c_lpModalTextContentsListTextBold">サイズ</div>
                    <div className="c_lpModalTextContentsListText">
                    1名様分
                    </div>
                  </li>
                </ul>
                <div className="c_lpModalAttention">
                  ※ 2024年12月21日（土）～12月25日（水）1Fスイーツ＆ベーカリー 粋にて店頭販売。
                </div>
                <div className="c_lpModalAttention">
                  
                </div>
                <a href="https://www.tablecheck.com/shops/royalparkhotel-tokyo-iki/reserve?menu_lists[]=66b0724a1f78308f6f9a5b20&num_people=1" target="_blank">
                  <div className="c_lpModalButtonChristmas">
                    ご予約はこちら →
                  </div>
                </a>
              </div>
            </div>
          </div>
        </CLpModal>
        )}
        {modalFlag11 && (
          <CLpModal onClose={openModal11} exClass="p_access_modal">
          <div className="c_lpModal">
            <div className="c_lpModalTitle u_sp">
            Stollen<br />
              <div className="c_lpModalTitleBold">
              シュトーレン
              </div>
            </div>
            <div className="c_lpModalImg u_pc">
              <img src="/assets/images/sp/christmas2024/cake/cake11-1.png" alt=""/>
            </div>
            <div className="c_lpModalImg u_sp">
              <img src="/assets/images/sp/christmas2024/cake/cake11-1.png" alt=""/>
            </div>
            <div className="c_lpModalTextWrap">
              <div>
                <div className="c_lpModalTitle u_pc">
                Stollen<br />
                <div className="c_lpModalTitleBold">
                シュトーレン
                </div>
                  <div className="lp_underbar"></div>
                  <div className="lp_underbar"></div>
                </div>
                <div className="c_lpModalText">
                ドライフルーツやナッツがたっぷり入ったドイツ伝統のパン菓子です。
                </div>
                <ul>
                  <li className="c_lpModalTextContentsList">
                    <div className="c_lpModalTextContentsListTextBold">料金</div>
                    <div className="c_lpModalTextContentsListText">
                    Lサイズ 2,916円<br />
                    Sサイズ 1,512円
                    </div>
                  </li>
                  <li className="c_lpModalTextContentsList">
                    <div className="c_lpModalTextContentsListTextBold">サイズ</div>
                    <div className="c_lpModalTextContentsListText">
                    Lサイズ 縦約18cm×横約12cm<br />
                    Sサイズ 縦約12cm×横約8cm
                    </div>
                  </li>
                </ul>
                <div className="c_lpModalAttention">
                  ※ 2024年12月21日（土）～12月25日（水）1Fスイーツ＆ベーカリー 粋にて店頭販売。<br />
                  ※ 2024年12月1日（日）よりご購入いただけます。
                </div>
                <div className="c_lpModalAttention">
                  
                </div>
                <a href="https://www.tablecheck.com/shops/royalparkhotel-tokyo-iki/reserve?menu_lists[]=66b0724a1f78308f6f9a5b20&num_people=1" target="_blank">
                  <div className="c_lpModalButtonChristmas">
                    ご予約はこちら →
                  </div>
                </a>
              </div>
            </div>
          </div>
        </CLpModal>
        )}
        {modalFlag12 && (
          <CLpModal onClose={openModal12} exClass="p_access_modal">
          <div className="c_lpModal">
            <div className="c_lpModalTitle u_sp">
            Brioche de Noël<br />
              <div className="c_lpModalTitleBold">
              ブリオッシュ・ド・ノエル
              </div>
            </div>
            <div className="c_lpModalImg u_pc">
              <img src="/assets/images/sp/christmas2024/cake/cake12-1.png" alt=""/>
            </div>
            <div className="c_lpModalImg u_sp">
              <img src="/assets/images/sp/christmas2024/cake/cake12-1.png" alt=""/>
            </div>
            <div className="c_lpModalTextWrap">
              <div>
                <div className="c_lpModalTitle u_pc">
                Brioche de Noël<br />
                <div className="c_lpModalTitleBold">
                ブリオッシュ・ド・ノエル
                </div>
                  <div className="lp_underbar"></div>
                  <div className="lp_underbar"></div>
                </div>
                <div className="c_lpModalText">
                バターと卵をたっぷり使った生地にシロップ漬けのフルーツを混ぜ、しっとりと焼き上げました。
                </div>
                <ul>
                  <li className="c_lpModalTextContentsList">
                    <div className="c_lpModalTextContentsListTextBold">料金</div>
                    <div className="c_lpModalTextContentsListText">
                    Lサイズ 1,296円<br />
                    Sサイズ 432円
                    </div>
                  </li>
                  <li className="c_lpModalTextContentsList">
                    <div className="c_lpModalTextContentsListTextBold">サイズ</div>
                    <div className="c_lpModalTextContentsListText">
                    Lサイズ 直径約10cm×高さ約13cm<br />
                    Sサイズ 直径約6.5cm×高さ約7cm
                    </div>
                  </li>
                </ul>
                <div className="c_lpModalAttention">
                  ※ 2024年12月21日（土）～12月25日（水）1Fスイーツ＆ベーカリー 粋にて店頭販売。
                </div>
                <div className="c_lpModalAttention">
                  
                </div>
                <a href="https://www.tablecheck.com/shops/royalparkhotel-tokyo-iki/reserve?menu_lists[]=66b0724a1f78308f6f9a5b20&num_people=1" target="_blank">
                  <div className="c_lpModalButtonChristmas">
                    ご予約はこちら →
                  </div>
                </a>
              </div>
            </div>
          </div>
        </CLpModal>
        )}
        {modalFlag13 && (
          <CLpModal onClose={openModal13} exClass="p_access_modal">
          <div className="c_lpModal">
            <div className="c_lpModalTitle u_sp">
            Merry Bear<br />
              <div className="c_lpModalTitleBold">
              メリーベア
              </div>
            </div>
            <div className="c_lpModalImg u_pc">
              <img src="/assets/images/sp/christmas2024/cake/cake13-1.png" alt=""/>
            </div>
            <div className="c_lpModalImg u_sp">
              <img src="/assets/images/sp/christmas2024/cake/cake13-1.png" alt=""/>
            </div>
            <div className="c_lpModalTextWrap">
              <div>
                <div className="c_lpModalTitle u_pc">
                Merry Bear<br />
                <div className="c_lpModalTitleBold">
                メリーベア
                </div>
                  <div className="lp_underbar"></div>
                  <div className="lp_underbar"></div>
                </div>
                <div className="c_lpModalText">
                さまざまな種類のチョコレートを使用し、多彩な味わいに仕上げたクリスマスチョコレート。カラフルなクリスマスツリーとくまのぬいぐるみの愛らしさにこだわった、ショコラティエの世界観をお楽しみください。
                </div>
                <ul>
                  <li className="c_lpModalTextContentsList">
                    <div className="c_lpModalTextContentsListTextBold">料金</div>
                    <div className="c_lpModalTextContentsListText">
                    4,536円
                    </div>
                  </li>
                  <li className="c_lpModalTextContentsList">
                    <div className="c_lpModalTextContentsListTextBold">限定数</div>
                    <div className="c_lpModalTextContentsListText">
                    50個／要予約
                    </div>
                  </li>
                  <li className="c_lpModalTextContentsList">
                    <div className="c_lpModalTextContentsListTextBold">サイズ</div>
                    <div className="c_lpModalTextContentsListText">
                    直径約9.5cm×高さ約11.5cm
                    </div>
                  </li>
                </ul>
                <div className="c_lpModalAttention">
                  ※ 限定商品のため、販売終了の際はご容赦ください。
                </div>
                <a href="https://www.tablecheck.com/shops/royalparkhotel-tokyo-iki/reserve?menu_lists[]=66b0724a1f78308f6f9a5b20&num_people=1" target="_blank">
                  <div className="c_lpModalButtonChristmas">
                    ご予約はこちら →
                  </div>
                </a>
              </div>
            </div>
          </div>
        </CLpModal>
        )}
        {modalFlag14 && (
          <CLpModal onClose={openModal14} exClass="p_access_modal">
          <div className="c_lpModal">
            <div className="c_lpModalTitle--18 u_sp">
            クリスマスパーティーボックス
            </div>
            <div className="c_lpModalImg u_pc">
              <img src="/assets/images/sp/christmas2024/homeparty/homeparty1.png" alt=""/>
            </div>
            <div className="c_lpModalImg u_sp">
              <img src="/assets/images/sp/christmas2024/homeparty/homeparty1.png" alt=""/>
            </div>
            <div className="c_lpModalTextWrap">
              <div>
                <div className="c_lpModalTitle u_pc">
                クリスマスパーティーボックス
                  <div className="lp_underbar"></div>
                  <div className="lp_underbar"></div>
                </div>
                <div className="c_lpModalText">
                オーブンで香ばしく焼き上げた鶏もも焼きを５本、カラフルなクリスマスサラダ、ブッシュ・ド・ノエルをセットにしました。パーティーテーブルを飾るにふさわしいセットです。
                </div>
                <ul>
                  <li className="c_lpModalTextContentsList">
                    <div className="c_lpModalTextContentsListTextBold">料金</div>
                    <div className="c_lpModalTextContentsListText">
                    12,960円／5名様分
                    </div>
                  </li>
                  <li className="c_lpModalTextContentsList">
                    <div className="c_lpModalTextContentsListTextBold">セット内容</div>
                    <div className="c_lpModalTextContentsListText">
                    ・鶏もも焼き×5本<br />
                    ・クリスマスサラダ<br />
                    ・オリジナルドレッシング<br />
                    ・ブッシュ・ド・ノエル<br />
                    ・ポテトとオニオンのソテー<br />
                    ・フラジョレ豆とベーコンの煮込み
                    </div>
                  </li>
                </ul>
                <div className="c_lpModalAttention">
                  ※ 要予約<br />
                  ※ 一部、温めが必要な商品を含みます。
                </div>
                <a href="https://www.tablecheck.com/shops/royalparkhotel-tokyo-iki/reserve?menu_lists[]=66b0724a1f78308f6f9a5b20&num_people=1" target="_blank">
                  <div className="c_lpModalButtonChristmas">
                    ご予約はこちら →
                  </div>
                </a>
              </div>
            </div>
          </div>
        </CLpModal>
        )}
        {modalFlag15 && (
          <CLpModal onClose={openModal15} exClass="p_access_modal">
            <div className="c_lpModal">
              <div className="c_lpModalTitle--18 u_sp">
              ローストビーフ
              </div>
              <div className="c_lpModalImg u_pc">
              <img src="/assets/images/sp/christmas2024/homeparty/homeparty2.png" alt=""/>
              </div>
              <div className="c_lpModalImg u_sp">
              <img src="/assets/images/sp/christmas2024/homeparty/homeparty2.png" alt=""/>
              </div>
              <div className="c_lpModalTextWrap">
                <div>
                  <div className="c_lpModalTitle u_pc">
                  ローストビーフ
                    <div className="lp_underbar"></div>
                    <div className="lp_underbar"></div>
                  </div>
                  <div className="c_lpModalText">
                  シンプルな味付けながらも牛肉の旨味をしっかりと閉じ込めるよう、じっくりオープンで焼き上げました。丁寧に仕込んだ味わい深いグレービーソースやふわふわのヨークシャープディングとともにご賞味ください。
                  </div>
                  <ul>
                    <li className="c_lpModalTextContentsList">
                      <div className="c_lpModalTextContentsListTextBold">料金</div>
                      <div className="c_lpModalTextContentsListText">
                      10,800円／4名様分
                      </div>
                    </li>
                    <li className="c_lpModalTextContentsList">
                      <div className="c_lpModalTextContentsListTextBold">セット内容</div>
                      <div className="c_lpModalTextContentsListText">
                      ・ポテトグラタン<br />
                      ・ヨークシャープディング<br />
                      ・チェリートマト<br />
                      ・フラジョレ豆とベーコンの煮込み
                      </div>
                    </li>
                  </ul>
                  <div className="c_lpModalAttention">
                    ※ 要予約<br />
                    ※ 温めが必要な商品です。
                  </div>
                  <a href="https://www.tablecheck.com/shops/royalparkhotel-tokyo-iki/reserve?menu_lists[]=66b0724a1f78308f6f9a5b20&num_people=1" target="_blank">
                  <div className="c_lpModalButtonChristmas">
                    ご予約はこちら →
                  </div>
                </a>
                </div>
              </div>
            </div>
          </CLpModal>
        )}
        {modalFlag16 && (
          <CLpModal onClose={openModal16} exClass="p_access_modal">
          <div className="c_lpModal">
            <div className="c_lpModalTitle--18 u_sp">
            ローストチキン
            </div>
            <div className="c_lpModalImg u_pc">
            <img src="/assets/images/sp/christmas2024/homeparty/homeparty3.png" alt=""/>
            </div>
            <div className="c_lpModalImg u_sp">
            <img src="/assets/images/sp/christmas2024/homeparty/homeparty3.png" alt=""/>
            </div>
            <div className="c_lpModalTextWrap">
              <div>
                <div className="c_lpModalTitle u_pc">
                ローストチキン
                  <div className="lp_underbar"></div>
                  <div className="lp_underbar"></div>
                </div>
                <div className="c_lpModalText">
                一羽丸ごと香草を詰め込み、香ばしく焼き上げました。皮はパリッと、チキンの旨味を存分に味わえる一品です。また、ポークのペーストソースをつけると、さらにおいしくお召しあがりいただけます。
                </div>
                <ul>
                  <li className="c_lpModalTextContentsList">
                    <div className="c_lpModalTextContentsListTextBold">料金</div>
                    <div className="c_lpModalTextContentsListText">
                    6,480円／4名様分
                    </div>
                  </li>
                  <li className="c_lpModalTextContentsList">
                    <div className="c_lpModalTextContentsListTextBold">セット内容</div>
                    <div className="c_lpModalTextContentsListText">
                    ・ポテトグラタン<br />
                    ・キノコのソテー<br />
                    ・チェリートマト<br />
                    ・フラジョレ豆とベーコンの煮込み<br />
                    ・ポークのペーストソース
                    </div>
                  </li>
                </ul>
                <div className="c_lpModalAttention">
                  ※ 要予約<br />
                  ※ 温めが必要な商品です。
                </div>
                <a href="https://www.tablecheck.com/shops/royalparkhotel-tokyo-iki/reserve?menu_lists[]=66b0724a1f78308f6f9a5b20&num_people=1" target="_blank">
                  <div className="c_lpModalButtonChristmas">
                    ご予約はこちら →
                  </div>
                </a>
              </div>
            </div>
          </div>
        </CLpModal>
        )}
        {modalFlag17 && (
          <CLpModal onClose={openModal17} exClass="p_access_modal">
          <div className="c_lpModal">
            <div className="c_lpModalTitle--18 u_sp">
            オードブル盛合せ
            </div>
            <div className="c_lpModalImg u_pc">
            <img src="/assets/images/sp/christmas2024/homeparty/homeparty4.png" alt=""/>
            </div>
            <div className="c_lpModalImg u_sp">
            <img src="/assets/images/sp/christmas2024/homeparty/homeparty4.png" alt=""/>
            </div>
            <div className="c_lpModalTextWrap">
              <div>
                <div className="c_lpModalTitle u_pc">
                オードブル盛合せ
                  <div className="lp_underbar"></div>
                  <div className="lp_underbar"></div>
                </div>
                <div className="c_lpModalText">
                華やかな彩りの冷製オードブルや温めてお召しあがりいただける温製オードブル。ワインやビールに合わせてお楽しみいただける一口サイズのメニューが充実したボックスです。
                </div>
                <ul>
                  <li className="c_lpModalTextContentsList">
                    <div className="c_lpModalTextContentsListTextBold">料金</div>
                    <div className="c_lpModalTextContentsListText">
                    7,560円／3名様分
                    </div>
                  </li>
                </ul>
                <div className="c_lpModalAttention">
                  ※ 要予約<br />
                  ※ 一部、温めが必要な商品を含みます。
                </div>
                <a href="https://www.tablecheck.com/shops/royalparkhotel-tokyo-iki/reserve?menu_lists[]=66b0724a1f78308f6f9a5b20&num_people=1" target="_blank">
                  <div className="c_lpModalButtonChristmas">
                    ご予約はこちら →
                  </div>
                </a>
              </div>
            </div>
          </div>
        </CLpModal>
        )}
        {modalFlag18 && (
          <CLpModal onClose={openModal18} exClass="p_access_modal">
          <div className="c_lpModal">
            <div className="c_lpModalTitle--18 u_sp">
            骨付き仔羊背肉ロースト
            </div>
            <div className="c_lpModalImg u_pc">
            <img src="/assets/images/sp/christmas2024/homeparty/homeparty5.png" alt=""/>
            </div>
            <div className="c_lpModalImg u_sp">
            <img src="/assets/images/sp/christmas2024/homeparty/homeparty5.png" alt=""/>
            </div>
            <div className="c_lpModalTextWrap">
              <div>
                <div className="c_lpModalTitle u_pc">
                骨付き仔羊背肉ロースト
                  <div className="lp_underbar"></div>
                  <div className="lp_underbar"></div>
                </div>
                <div className="c_lpModalText">
                多彩なハーブで十分に味と香りを染み込ませて焼き上げた、旨味たっぷりの仔羊のローストです。
                </div>
                <ul>
                  <li className="c_lpModalTextContentsList">
                    <div className="c_lpModalTextContentsListTextBold">料金</div>
                    <div className="c_lpModalTextContentsListText">
                    4,320円／１名様分
                    </div>
                  </li>
                  <li className="c_lpModalTextContentsList">
                    <div className="c_lpModalTextContentsListTextBold">セット内容</div>
                    <div className="c_lpModalTextContentsListText">
                    ・ラタトゥイユ<br />
                    ・キノコのソテー<br />
                    ・フラジョレ豆とベーコンの煮込み
                    </div>
                  </li>
                </ul>
                <div className="c_lpModalAttention">
                  ※ 要予約<br />
                  ※ 温めが必要な商品です。<br />
                  ※ 写真は２名様分です。
                </div>
                <a href="https://www.tablecheck.com/shops/royalparkhotel-tokyo-iki/reserve?menu_lists[]=66b0724a1f78308f6f9a5b20&num_people=1" target="_blank">
                  <div className="c_lpModalButtonChristmas">
                    ご予約はこちら →
                  </div>
                </a>
              </div>
            </div>
          </div>
        </CLpModal>
        )}
        {modalFlag19 && (
           <CLpModal onClose={openModal19} exClass="p_access_modal">
           <div className="c_lpModal">
             <div className="c_lpModalTitle--18 u_sp">
             ビーフシチュー
             </div>
             <div className="c_lpModalImg u_pc">
             <img src="/assets/images/sp/christmas2024/homeparty/homeparty6.png" alt=""/>
             </div>
             <div className="c_lpModalImg u_sp">
             <img src="/assets/images/sp/christmas2024/homeparty/homeparty6.png" alt=""/>
             </div>
             <div className="c_lpModalTextWrap">
               <div>
                 <div className="c_lpModalTitle u_pc">
                 ビーフシチュー
                   <div className="lp_underbar"></div>
                   <div className="lp_underbar"></div>
                 </div>
                 <div className="c_lpModalText">
                 じっくり丁寧に煮込んだ芳醇な香りのビーフシチュー。口にした瞬間、お肉がほろほろと崩れるような食感が特徴です。
                 </div>
                 <ul>
                   <li className="c_lpModalTextContentsList">
                     <div className="c_lpModalTextContentsListTextBold">料金</div>
                     <div className="c_lpModalTextContentsListText">
                     3,240円／１名様分
                     </div>
                   </li>
                   <li className="c_lpModalTextContentsList">
                     <div className="c_lpModalTextContentsListTextBold">セット内容</div>
                     <div className="c_lpModalTextContentsListText">
                    ・ポテトグラタン<br />
                    ・キノコのソテー<br />
                    ・インゲンのボイル<br />
                    ・キャロットグラッセ
                     </div>
                   </li>
                 </ul>
                 <div className="c_lpModalAttention">
                   ※ 要予約<br />
                   ※ 温めが必要な商品です。<br />
                 </div>
                 <a href="https://www.tablecheck.com/shops/royalparkhotel-tokyo-iki/reserve?menu_lists[]=66b0724a1f78308f6f9a5b20&num_people=1" target="_blank">
                   <div className="c_lpModalButtonChristmas">
                     ご予約はこちら →
                   </div>
                 </a>
               </div>
             </div>
           </div>
         </CLpModal>
        )}
        {modalFlag20 && (
           <CLpModal onClose={openModal20} exClass="p_access_modal">
           <div className="c_lpModal">
             <div className="c_lpModalTitle--18 u_sp">
             鶏もも焼き
             </div>
             <div className="c_lpModalImg u_pc">
             <img src="/assets/images/sp/christmas2024/homeparty/homeparty7.png" alt=""/>
             </div>
             <div className="c_lpModalImg u_sp">
             <img src="/assets/images/sp/christmas2024/homeparty/homeparty7.png" alt=""/>
             </div>
             <div className="c_lpModalTextWrap">
               <div>
                 <div className="c_lpModalTitle u_pc">
                 鶏もも焼き
                   <div className="lp_underbar"></div>
                   <div className="lp_underbar"></div>
                 </div>
                 <div className="c_lpModalText">
                 鶏もも肉に照り焼きソースを絡めてオーブンで香ばしく焼き上げました。お好みでスパイスをかけてどうぞ。
                 </div>
                 <ul>
                   <li className="c_lpModalTextContentsList">
                     <div className="c_lpModalTextContentsListTextBold">料金</div>
                     <div className="c_lpModalTextContentsListText">
                     1本　1,080円
                     </div>
                   </li>
                 </ul>
                 <div className="c_lpModalAttention">
                   ※ 2024年12月21日（土）～12月25日（水）1Fスイーツ＆ベーカリー 粋にて店頭販売。<br />
                   ※ 温めが必要な商品です。<br />
                 </div>
                 <a href="https://www.tablecheck.com/shops/royalparkhotel-tokyo-iki/reserve?menu_lists[]=66b0724a1f78308f6f9a5b20&num_people=1" target="_blank">
                   <div className="c_lpModalButtonChristmas">
                     ご予約はこちら →
                   </div>
                 </a>
               </div>
             </div>
           </div>
         </CLpModal>
        )}
        {modalFlag21 && (
           <CLpModal onClose={openModal21} exClass="p_access_modal">
           <div className="c_lpModal">
             <div className="c_lpModalTitle--18 u_sp">
             コースメニュー -French-
             </div>
             <div className="c_lpModalImg u_pc">
             <img src="/assets/images/sp/christmas2024/course/course1.png" alt=""/>
             </div>
             <div className="c_lpModalImg u_sp">
             <img src="/assets/images/sp/christmas2024/course/course1.png" alt=""/>
             </div>
             <div className="c_lpModalTextWrap">
               <div>
                 <div className="c_lpModalTitle u_pc">
                 コースメニュー -French-
                   <div className="lp_underbar"></div>
                   <div className="lp_underbar"></div>
                 </div>
                 <div className="c_lpModalText">
                 ご自宅でのクリスマスを華やかに演出するコース料理をご用意いたしました。舌平目のポッシェや、フォアグラと牛ヒレ肉のポワレなど、シェフが趣向を凝らしたメニューをご堪能ください。
                 </div>
                 <ul>
                   <li className="c_lpModalTextContentsList">
                     <div className="c_lpModalTextContentsListTextBold">料金</div>
                     <div className="c_lpModalTextContentsListText">
                     14,040円／１名様分
                     </div>
                   </li>
                 </ul>
                 <ul>
                   <li className="c_lpModalTextContentsListCourse">
                     <div className="c_lpModalTextContentsListTextBold">コース内容</div>
                     <div className="c_lpModalTextContentsListText">
                    ・オマール海老とマンゴーのアンサンブル ターメリック香るクリュスタセのエッセンス<br />
                    ・聖護院蕪とパンチェッタのクリームスープ<br />
                    ・舌平目のポッシェとエゾ鮑 白ワインソース 雲丹風味<br />
                    ・牛ヒレ肉とフォアグラのポワレ トリュフソース ポテトグラタンと温野菜<br />
                    ・ブッシュ・ド・ノエル<br />
                    ・パンとバター
                     </div>
                   </li>
                 </ul>
                 <div className="c_lpModalAttention">
                   ※ 要予約<br />
                   ※ 一部、温めが必要な商品を含みます。
                 </div>
                 <a href="https://www.tablecheck.com/shops/royalparkhotel-tokyo-iki/reserve?menu_lists[]=66b0724a1f78308f6f9a5b20&num_people=1" target="_blank">
                   <div className="c_lpModalButtonChristmas">
                     ご予約はこちら →
                   </div>
                 </a>
               </div>
             </div>
           </div>
         </CLpModal>
        )}
        {modalFlag22 && (
           <CLpModal onClose={openModal22} exClass="p_access_modal">
           <div className="c_lpModal">
             <div className="c_lpModalTitle--18 u_sp">
             コースメニュー -Chinese-
             </div>
             <div className="c_lpModalImg u_pc">
             <img src="/assets/images/sp/christmas2024/course/course2.png" alt=""/>
             </div>
             <div className="c_lpModalImg u_sp">
             <img src="/assets/images/sp/christmas2024/course/course2.png" alt=""/>
             </div>
             <div className="c_lpModalTextWrap">
               <div>
                 <div className="c_lpModalTitle u_pc">
                 コースメニュー -Chinese-
                   <div className="lp_underbar"></div>
                   <div className="lp_underbar"></div>
                 </div>
                 <div className="c_lpModalText">
                 ご自宅でのクリスマスを華やかに演出するコース料理をご用意いたしました。タラバガニやフカヒレ、合鴨などの豪華な食材は、魅力あふれる多彩な味わいをお楽しみいただけます。 
                 </div>
                 <ul>
                   <li className="c_lpModalTextContentsList">
                     <div className="c_lpModalTextContentsListTextBold">料金</div>
                     <div className="c_lpModalTextContentsListText">
                     8,640円／１名様分
                     </div>
                   </li>
                 </ul>
                 <ul>
                   <li className="c_lpModalTextContentsListCourse">
                     <div className="c_lpModalTextContentsListTextBold">コース内容</div>
                     <div className="c_lpModalTextContentsListText">
                      ・前菜盛合せ<br />
                      ・タラバガニとほうれん草のフカヒレスープ<br />
                      ・合鴨のスモーク 蒸しパンと野菜を添えて<br />
                      ・和牛と野菜の炒め<br />
                      ・大海老と野菜の辛味甘酢ソース<br />
                      ・ビーツの炒飯 クリームコーンソース<br />
                      ・イチゴソースの杏仁豆腐と紫芋のタピオカミルク
                     </div>
                   </li>
                 </ul>
                 <div className="c_lpModalAttention">
                   ※ 要予約<br />
                   ※ 一部、温めが必要な商品を含みます。
                 </div>
                 <a href="https://www.tablecheck.com/shops/royalparkhotel-tokyo-iki/reserve?menu_lists[]=66b0724a1f78308f6f9a5b20&num_people=1" target="_blank">
                   <div className="c_lpModalButtonChristmas">
                     ご予約はこちら →
                   </div>
                 </a>
               </div>
             </div>
           </div>
         </CLpModal>
        )}
      </div>
    </LayoutLp>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
